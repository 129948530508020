<template>
    <div class="sidbar-logo"><el-image src="/images/tecdologo.png" /></div>
    <el-scrollbar>
      <el-menu>
        <el-menu-item index="1">主界面</el-menu-item>
        <el-menu-item index="2">聊天重置</el-menu-item>
      </el-menu>
    </el-scrollbar>
    <div class="sidbar-user"></div>
</template>

<script>

export default {
  name: 'SideBar',
  components: {},
  data() {
    return {

  }},
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.sidbar-logo {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidbar-logo .el-image {
  max-width: 100%;
  max-height: 100%;
}
.el-scrollbar {
  height: 70%;
}
.el-menu {
  border: 0;
  --el-menu-text-color: var(--sidebar-menu-text-color);
  --el-menu-active-color: var(--sidebar-menu-active-text-color);
  --el-menu-bg-color: var(--sidebar-menu-bg-color);
  --el-menu-hover-bg-color: var(--sidebar-menu-hover-bg-color);
}
.el-menu .el-menu-item {
  border-radius: 10px;
  margin: 10px;
  height: var(--sidebar-menu-item-height);
  font-size: 18px;
}
.sidbar-user {
  height: 30%;
  // border-top: 1px solid gray;
}
</style>