
export function getNowTimeString() {
    var currentDate = new Date(); 
    var year = currentDate.getFullYear(); 
    var month = String(currentDate.getMonth() + 1).padStart(2, '0'); 
    var day = String(currentDate.getDate()).padStart(2, '0'); 
    var hours = String(currentDate.getHours()).padStart(2, '0'); 
    var minutes = String(currentDate.getMinutes()).padStart(2, '0'); 
    var seconds = String(currentDate.getSeconds()).padStart(2, '0'); 
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getDevice() {
    var ua = navigator.userAgent.toLowerCase();
    var isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua);
    if (isMobile) return 'mobile';
    else return 'pc';
}
