<template>
<section class="app-main">
    <router-view v-slot="{ Component, route }">
    <transition name="fade-transform" mode="out-in">
        <keep-alive :include="tagsViewStore.cachedViews">
        <component :is="Component" :key="route.path" />
        </keep-alive>
    </transition>
    </router-view>
</section>
</template>

<script>
import { useTagsViewStore } from '@/store/modules/tagsView'

export default {
  name: 'AppMain',
  components: {},
  data() {
    return {
      tagsViewStore: useTagsViewStore(),

  }},
  methods: {

  },
}
</script>

<style lang="scss" scoped>

</style>