
import Layout from "@/layout"


export default [
    {
        path: '/',
        redirect: '/chat'
    },
    {
        path: '/chat',
        component: Layout,
        children: [
            {
                path: "",
                component: () => import("@/views/chat/index.vue"),
                name: "Chat",
                meta: {}
            }
        ]
    },
]