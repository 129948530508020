
import { createWebHistory, createRouter } from 'vue-router'

import constantRouters from './constant'


const constantRoutes = [
    ...constantRouters
]

const asyncRoutes = [
    
]

export default createRouter({
    history: createWebHistory(),
    routes: [...constantRoutes, ...asyncRoutes],
    scrollBehavior: () => ({ y: 0 }),
})