<template>
  <el-container :class="classList">
    <el-aside :collapse="isCollapse"><Sidebar/></el-aside>
    <el-container>
      <el-header>
        <el-button class="sidebar-button" v-if="isSidebarButton">
          <el-icon :size="20"><Operation /></el-icon></el-button>
        <h1>钛动出海学院</h1>
      </el-header>
      <el-main><AppMain/></el-main>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from './components/Sidebar'
import AppMain from './components/AppMain'
import { getDevice } from '@/utils/tool'
import { Operation } from '@element-plus/icons-vue'

export default {
  name: 'LayoutMain',
  components: {Sidebar, AppMain, Operation},
  data() {
    return {
      isSidebar: getDevice() != 'mobile',
      isSidebarButton: getDevice() == 'mobile'
  }},
  created() {
    
  },
  computed: {
    classList() { return {
      mobile: getDevice() == 'mobile',
      hide_sidebar: !this.isSidebar
    }}
  },
  methods: {
      handleClickOutside() {

      }
  },
}
</script>

<style lang="scss" scoped>
.el-aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  width: var(--sidebar-width);
  height: 100%;
  transition: width 0.28s;
  color: var(--sidebar-menu-text-color);
  background-color: var(--sidebar-menu-bg-color);
  overflow: hidden;
}

.el-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  width: calc(100% - var(--sidebar-width));
  height: var(--header-height);
  transition: width 0.28s;
  font-size: 16px;
  color: var(--header-text-color);
  text-align: center;
  background-color: var(--header-background-color);
  box-shadow: var(--el-box-shadow-lighter);
}

.el-main {
  position: fixed;
  top: var(--header-height);
  right: 0;
  z-index: 1000;
  width: calc(100% - var(--sidebar-width));
  height: calc(100% - var(--header-height));
  transition: width 0.28s;
  background-color: var(--chat-background-color);
  padding: 0;
}

.sidebar-button {
  position: absolute;
  left: 0;
  height: 100%;
  width: 70px;
  border: 0;
}

.mobile {
  .el-header {
    width: 100%;
  }
  .el-main {
    width: 100%;
  }
}

.hide_sidebar {
  .el-aside {
    width: 0;
  }
}
</style>